<template>
  <ItemList
    :items="vendorSegments"
    :is-loading="isLoadingList"
    no-data-text="Nenhum Ramo de atuação encontrado"
    label="Ramo de atuação"
    @addItem="addVendorSegment"
    @removeItem="removeVendorSegment"
  />
</template>

<script>
export default {
  components: {
    ItemList: () => import('./ItemList.vue'),
  },
  data: () => ({
    vendorSegments: [],
    isLoadingList: false,
  }),
  mounted() {
    if (this.vendorSegments.length === 0) {
      this.getSegments()
    }
  },
  methods: {
    getSegments() {
      this.isLoadingList = true
      this.$api
        .get('lines')
        .then((res) => {
          this.vendorSegments = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingList = false
        })
    },
    addVendorSegment(value) {
      this.$api
        .post('lines/store', { name: value })
        .then((res) => {
          this.vendorSegments.push(res.data.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeVendorSegment(item) {
      this.$api
        .post('lines/delete', item)
        .then(() => {
          let idx = this.vendorSegments.indexOf(item)
          this.vendorSegments.splice(idx, 1)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>